<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Feature Service" />

      <div class="page-background d-flex flex-column align-center px-1 py-2">
        <section>
          <div class="caption">
            <v-icon>{{ mdiLayersPlus }}</v-icon>
            Convert Feature Service
          </div>
          <v-card
            class="my-1"
            :width="$vuetify.breakpoint.xsOnly ? '100%' : 'auto'"
          >
            <v-card-text class="d-flex align-center gap px-5">
              <section>
                <div class="caption">Select Layer</div>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Layer"
                  rules="required"
                >
                  <v-autocomplete
                    autocomplete="off"
                    v-model="mapServiceId"
                    label="Layer"
                    :items="mapServiceChoices"
                    item-text="label"
                    item-value="value"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    hide-details
                    @change="getGisFieldsAndFeatures"
                  ></v-autocomplete>
                </validation-provider>
              </section>
              <section>
                <div class="caption">Select Fields</div>
                <validation-provider v-slot="{ errors, valid }" name="Field">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="selectedFields"
                    label="Field"
                    :items="fieldChoices"
                    item-text="label"
                    item-value="value"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    hide-details
                    multiple
                  ></v-autocomplete>
                </validation-provider>
              </section>
              <v-btn color="primary" @click="convertLayer">Convert</v-btn>
            </v-card-text>
          </v-card>
        </section>
      </div>

      <v-dialog :value="showConvertSuccessDialog" max-width="500px" persistent>
        <v-card>
          <v-card-text class="py-3">
            Feature layer conversion is successful.
          </v-card-text>

          <v-card-actions class="d-flex justify-end px-5 py-3">
            <v-btn
              text
              color="primary"
              @click="showConvertSuccessDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog :value="showConvertFailureDialog" max-width="500px" persistent>
        <v-card>
          <v-card-text class="py-3">
            <p>Feature layer conversion failed.</p>
            <p v-if="errorMessage">{{ errorMessage }}</p>
          </v-card-text>

          <v-card-actions class="d-flex justify-end px-5 py-3">
            <v-btn
              text
              color="primary"
              @click="showConvertFailureDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import {
  mdiExport,
  mdiInformation,
  mdiSendVariant,
  mdiLayersPlus,
} from "@mdi/js";
import axios from "axios";
import generateSingleSymbol from "@/mixins/generateSingleSymbol";
import { loadModules } from "esri-loader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "FeatureService",
  components: {
    TopBar,
  },
  data() {
    return {
      mdiExport,
      mdiInformation,
      mdiSendVariant,
      mdiLayersPlus,
      mapServiceId: "",
      selectedFields: [],
      mapServices: [],
      gisFields: [],
      gisLayerFeatures: [],
      objectIdFieldName: "",
      globalIdFieldName: "",
      showConvertSuccessDialog: false,
      showConvertFailureDialog: false,
      spatialReference: {},
      errorMessage: undefined,
    };
  },
  computed: {
    mapServiceChoices() {
      return this.mapServices
        .filter((l) => l.service_type === "F")
        .map((l) => {
          const { service_name: label, map_service_id: value } = l;
          return { label, value };
        });
    },
    fieldChoices() {
      return this.gisFields.map((f) => {
        const { name, alias } = f;
        return { label: alias ?? name, value: name };
      });
    },
  },
  methods: {
    async getLayers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services`);
      this.mapServices = results.filter((r) => r.service_type === "F");
    },
    async getGisFieldsAndFeatures() {
      const { mapServiceId } = this;
      const {
        data: { results: mapService },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      const mapServiceUrl = mapService?.service_url;

      const token =
        mapService.token_type === "AGOL"
          ? localStorage.getItem("esri_token")
          : undefined;
      const params = {
        where: "1=1",
        outFields: "*",
        f: "pjson",
        token,
      };

      const { data: queryResult } = await axios.get(`${mapServiceUrl}/query`, {
        params: { ...params },
        transformRequest: (data, headers) => {
          delete headers["X-AUTH"];
          return data;
        },
      });
      const {
        fields,
        features,
        globalIdFieldName,
        objectIdFieldName,
        spatialReference,
      } = queryResult;
      this.gisFields =
        fields?.filter(
          (f) => !["esriFieldTypeOID", "esriFieldTypeGlobalID"].includes(f.type)
        ) ?? [];
      this.gisLayerFeatures = features ?? [];
      this.objectIdFieldName = objectIdFieldName;
      this.globalIdFieldName = globalIdFieldName;
      this.spatialReference = spatialReference;
    },
    async convertLayer() {
      try {
        const { mapServiceId: oldMapServiceId } = this;
        const mapService = await this.createMapService();
        const renderer = await this.addRenderer(mapService.map_service_id);
        await this.addRendererSymbols(renderer.renderer_id);
        const gisDataPoints = await this.addGisDataPoints(
          mapService.map_service_id
        );

        if (this.errorMessage) {
          return;
        }

        const {
          data: { results: oldNewFieldMappings },
        } = await axios.post(`${APIURL}/gis_data_fields/copy`, {
          old_map_service_id: oldMapServiceId,
          new_map_service_id: mapService.map_service_id,
        });

        const gisDataFields = await this.createGisDataFieldsFromGisFields(
          mapService.map_service_id
        );

        await this.addGisDataValues(gisDataPoints, gisDataFields);

        await this.moveItemsToNewLayer(
          oldMapServiceId,
          mapService.map_service_id,
          gisDataPoints,
          oldNewFieldMappings
        );
        this.showConvertSuccessDialog = true;
      } catch (error) {
        console.log(error);
        this.showConvertFailureDialog = true;
      }
    },
    async createMapService() {
      const { user_group_id: userGroupId } = JSON.parse(
        localStorage.getItem("auth")
      );
      const {
        data: { results: existingMapService },
      } = await axios.get(`${APIURL}/map_services/${this.mapServiceId}`);
      const { ref_field: refField, service_name: serviceName } =
        existingMapService;
      const payload = {
        service_type: "U",
        user_group_id: userGroupId,
        ref_field: refField,
        service_name: `${serviceName} Copy`,
      };
      const {
        data: {
          results: { map_service: mapService },
        },
      } = await axios.post(`${APIURL}/map_services`, payload);
      return mapService;
    },
    async addRenderer(mapServiceId) {
      const {
        data: { results: mapService },
      } = await axios.get(`${APIURL}/map_services/${mapServiceId}`);
      const { ref_field: fieldToReference } = mapService;
      const payload = {
        type: "Point: Picture",
        convert_date_to_days: false,
        reference_field: fieldToReference,
        renderer_type: "simple",
        apply_renderer_to_feature_service: false,
        map_service_id: mapServiceId,
      };
      const {
        data: { results },
      } = await axios.post(`${APIURL}/renderers`, payload);
      return results.renderer;
    },
    async addRendererSymbols(rendererId) {
      const rendererSymbols = [
        {
          renderer_id: rendererId,
          ...generateSingleSymbol(),
          symbol_type: "image",
        },
      ];
      const payload = {
        renderer_symbols: rendererSymbols,
      };
      const {
        data: { results },
      } = await axios.post(`${APIURL}/renderer_symbol/batch`, payload);
      return results;
    },
    async addGisDataPoints(mapServiceId) {
      if (this.gisLayerFeatures.length === 0) {
        return;
      }
      const [webMercatorUtils, SpatialReference, projection] =
        await loadModules([
          "esri/geometry/support/webMercatorUtils",
          "esri/geometry/SpatialReference",
          "esri/geometry/projection",
        ]);

      // Initialize the projection engine
      await projection.load();

      // Create the geographic coordinate system (WGS84 - WKID: 4326)
      const geographicSR = new SpatialReference({ wkid: 4326 });

      const gisDataPoints = this.gisLayerFeatures
        .filter((f) => typeof f.geometry === "object" && f.geometry !== null)
        .map((f) => {
          const {
            geometry: { x, y },
            attributes,
          } = f;

          let lon, lat;

          try {
            if (
              this.spatialReference.wkid === 102100 ||
              this.spatialReference.wkid === 3857
            ) {
              // Web Mercator - use specialized conversion for better performance
              [lon, lat] = webMercatorUtils.xyToLngLat(x, y);
            } else {
              // For all other spatial references, use the projection engine
              const point = {
                x,
                y,
                spatialReference: this.spatialReference,
              };

              // Project the point to WGS84
              const projectedPoint = projection.project(point, geographicSR);
              lon = projectedPoint.x;
              lat = projectedPoint.y;
            }
          } catch (error) {
            this.errorMessage = `Projection error for WKID ${this.spatialReference.wkid}: ${error}`;
            this.showConvertFailureDialog = true;
            return;
          }

          const global_id = uuidv4();
          return {
            global_id: global_id,
            object_id: attributes[this.objectIdFieldName],
            map_service_id: mapServiceId,
            latitude: lat,
            longitude: lon,
            old_global_id: attributes[this.globalIdFieldName],
            new_global_id: global_id,
            old_object_id: attributes[this.objectIdFieldName],
            new_object_id: attributes[this.objectIdFieldName],
          };
        });

      const payload = {
        gis_data_points: gisDataPoints,
      };
      await axios.post(`${APIURL}/gis_data_points/batch`, payload);
      return [...gisDataPoints];
    },
    async createGisDataFieldsFromGisFields(mapServiceId) {
      if (this.selectedFields.length === 0) {
        return;
      }
      const gisDataFields = this.gisFields
        .filter((f) => {
          return this.selectedFields.includes(f.name);
        })
        .map((f) => {
          let type = "string";
          const { name, alias, type: esriFieldType } = f;
          if (
            [
              "esriFieldTypeInteger",
              "esriFieldTypeSmallInteger",
              "esriFieldTypeDouble",
              "esriFieldTypeSingle",
            ].includes(esriFieldType)
          ) {
            type = "number";
          } else if ("esriFieldTypeString" === esriFieldType) {
            type = "string";
          } else if ("esriFieldTypeDate" === esriFieldType) {
            type = "date";
          } else if ("esriFieldTypeOID" === esriFieldType) {
            type = "object_id";
          } else if ("esriFieldTypeGlobalID" === esriFieldType) {
            type = "global_id";
          }
          return {
            gis_data_field_id: uuidv4(),
            map_service_id: mapServiceId,
            name: name ?? alias,
            type,
            editable: true,
            visible: true,
            visible_when_shared: true,
            global_id: null,
            esri_field_type: esriFieldType,
          };
        });
      const payload = {
        gis_data_fields: gisDataFields,
      };
      const {
        data: { results },
      } = await axios.post(`${APIURL}/gis_data_fields/batch`, payload);
      return results;
    },
    async addGisDataValues(gisDataPoints, gisDataFields) {
      const { gisLayerFeatures, objectIdFieldName, globalIdFieldName } = this;
      if (!Array.isArray(gisDataPoints) || !Array.isArray(gisDataFields)) {
        return;
      }

      const gisDataValues = gisLayerFeatures
        .map((f) => {
          const { attributes } = f;
          return gisDataFields.map((gdf) => {
            const { gis_data_field_id: gisDataFieldId, name } = gdf;
            // Find the matching gisDataPoint using old_global_id
            const matchingPoint = gisDataPoints.find(
              (point) => point.old_global_id === attributes?.[globalIdFieldName]
            );
            return {
              gis_data_value_id: uuidv4(),
              gis_data_field_id: gisDataFieldId,
              feature_id: attributes?.[objectIdFieldName],
              global_id: matchingPoint?.new_global_id, // Use the new_global_id
              value:
                gdf.esri_field_type === "esriFieldTypeDate"
                  ? moment.utc(attributes?.[name]).format("MM/DD/YYYY")
                  : attributes?.[name],
            };
          });
        })
        .flat();
      const payload = {
        gis_data_values: gisDataValues,
      };
      const {
        data: { results },
      } = await axios.post(`${APIURL}/gis_data_values/insert/batch`, payload);
      return results;
    },
    async moveItemsToNewLayer(
      oldMapServiceId,
      newMapServiceId,
      gisDataPoints,
      oldNewFieldMappings
    ) {
      // Break gisDataPoints into chunks of 100
      for (let i = 0; i < gisDataPoints.length; i += 100) {
        const chunk = gisDataPoints.slice(i, i + 100);
        const payload = {
          old_map_service_id: oldMapServiceId,
          new_map_service_id: newMapServiceId,
          gis_data_points: chunk,
          old_new_field_mappings: oldNewFieldMappings,
        };
        await axios.post(`${APIURL}/map_services/move_entities`, payload);
      }
    },
  },
  beforeMount() {
    this.getLayers();
  },
};
</script>

<style scoped>
.circle {
  background-color: #375f54;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.gap {
  gap: 15px;
}
</style>
